<template>
  <div v-show="kyc && kyc.status === -1" class="kyc_reason">
    <div>
      <div class="kyc_reason_title">
        <span>
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99992 15.1673C11.6666 15.1673 14.6666 12.1673 14.6666 8.50065C14.6666 4.83398 11.6666 1.83398 7.99992 1.83398C4.33325 1.83398 1.33325 4.83398 1.33325 8.50065C1.33325 12.1673 4.33325 15.1673 7.99992 15.1673Z"
              stroke="#CC1717"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 5.83398V9.16732"
              stroke="#CC1717"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.99634 11.166H8.00233"
              stroke="#CC1717"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <h4>{{ $t("identityNotVerified") }}</h4>
      </div>
      <p>
        {{ kyc.rejection_reason }}
      </p>
    </div>

    <div class="arrow_right">
      <svg
        width="6"
        height="9"
        viewBox="0 0 6 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.45508 8.45906L4.71508 5.19906C5.10008 4.81406 5.10008 4.18406 4.71508 3.79906L1.45508 0.539062"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Kyc Reason",
  props: {
    kyc: {
      type: Object,
      required: true,
      default: null,
    },
  },
};
</script>

<style scoped>
.kyc_reason {
  display: flex;
  /* gap: 10px; */
  background-color: var(--kyc-reason-bg);
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  margin: 8px auto 14px auto;
  justify-content: space-between;
}
.kyc_reason_title {
  display: flex;
  gap: 8px;
}
.kyc_reason_title h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 18.9px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--light-red-shade);
}
.kyc_reason p {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: var(--help-sub-text);
}
.arrow_right {
  background-color: #0e0d0d;
  padding: 8px;
  gap: 4px;
  border-radius: 4px;
  opacity: 0px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}
</style>
